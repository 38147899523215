<template>
<div>
    <market />
</div>
</template>

<script>
import market from '../components/dashboard/GeneralMarket'
export default {
    components: {
        market
    }
}
</script>

<style>
